.settings {
    font-size: small;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    height: 95%;
    width: 100%;
}
.settings .settings-body {
    left: 0;
    top: 5%;
    height: 90%;
    width: 100%;
    position: fixed;
    padding: 1.5rem;
    overflow: auto;
}
.settings .settings-body .settings-section {
    width: 50rem;
    max-width: 100%;
    margin: 0 auto;
}
.settings .settings-body p,
.settings .settings-body h2 {
    text-align: left;
}

.changelog,
.faq
{
    padding: 2rem;
}
.change-list {
    list-style: none;
    padding: 0;
}
.change {
    display: flex;
    flex-direction: row;
    background-color: #779977;
    color: #000;
    border: 2px solid #060;
    border-radius: 4px;
    margin: 2rem 5% 2rem 2rem;
    padding: 0.5rem;
    text-align: left;
}

.change .info {
    width: 30%;
    margin: auto 0;
    padding-left: 0.5rem;
}

.features-list {
    display: inline-block;
    float: right;
    width: 70%;
}
.feature {
}
.faq h3 {
    color: #28a745;
}
