html {
  touch-action: manipulation;
}

body {
  padding-bottom: 5%;
}

.app-nav {
  position: fixed;
  width: 100%;
  height: 5%;
  top: 95%;
  left: 0;
}

.app-nav button {
  display: inline-block;
  width: 25%;
  margin: 0;
  border: 2px solid #aab;
  border-radius: 2px;
  color: #444; background-color: #BBC;
  height: 100%;
}

.app-nav button:active,
.app-nav button:focus,
.app-nav button:hover {
  background-color: #AAB;
}

.app-nav button.active {
  background-color: #DDE;
}
