.history {
    font-size: small;
}
.history .history-body {
    overflow: auto;
    top: 5%;
    height: 90%;
    left: 0;
    width: 100%;
    position: fixed;
}
.history ul {
    background-color: #242;
    list-style-type: none;
    margin: 0 auto;
    padding-left: 3rem;
    padding-right: 3rem;
    /* So that the div takes up the minimum necessary width */
    display: table;
}
li.history-item {
    background-color: #ACA;
    border: 2px solid #060;
    border-radius: 4px;
    margin: 2rem;
    padding: 0.5rem;
    text-align: left;
    word-break: break-all;
}
li.history-item:hover {
    background-color: #EFE;
}
.history ul.helpers-details,
.history ul.results-set {
    background-color: #ACA;
    list-style-type: square;
    margin-left: 0;
}
.history li.history-item:hover ul {
    background-color: #EFE;
}

li.history-item.active {
    background-color: #AFA;
    border: 2px solid #2C2;
}
li.history-item.active:hover {
    background-color: #F6FCF6;
}
li.history-item.active ul {
    background-color: #AFA;
}
li.history-item.active:hover ul {
    background-color: #F6FCF6;
}
