.btn-faint {
    border-color: #eee;
}
.btn-faint:hover {
    border-color: #888;
}
.btn-invisible:hover {
    border-color: #ccc;
    color: #444444;
}

.btn-left-of-row {
    height: 44px;
    width: 44px
}

@media only screen and (min-width: 650px) {
    .btn-left-of-row {
        left: -50px;
        margin-right: -50px;
        position: relative;
    }
    .btn-left-of-row + div {
         margin-left: 6px;
    }
}
