.trash-button {
    float: right;
    margin-left: 2rem;
}

.saved-roll ul.results-set {
    margin-left: 0;
    margin-right: 1rem;
    text-align: left;
    display: inline-block;
}
