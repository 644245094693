.calculator .calculator-display,
.calculator .calculator-display div {
    background-color: #E6E6FF;
}
.calculator .calculator-display .formula {
    color: #332;
    text-align: left;
    font-size: 1.3rem;
    word-break: break-all;
}
.calculator .calculator-display .result {
    color: #332;
    text-align: right;
    font-size: 3.5rem;
}
.calculator .calculator-display .rolls {
    color: #332;
    text-align: right;
    font-size: 1.6rem;
    font-style: italic;
}

.calculator .calculator-display textarea.formula-editor {
    width: 100%;
    font-size: 16px;
    height: auto;
}

@media only screen and (max-device-width: 413px) {
    .calculator .calculator-display .result {
        font-size: 1.8rem;
    }
    .calculator .calculator-display .rolls {
        font-size: 1.0rem;
    }
}

