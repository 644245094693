.calculator .buttons button {
    color: #444;
    min-width: 22%;
}

.calculator button.number {
    background-color: #EEE;
}
.calculator button.number:active,
.calculator button.number:focus,
.calculator button.number:hover {
    background-color: #CCC;
}
.calculator button.arithmetic {
    background-color: #BBC;
}
.calculator button.arithmetic:active,
.calculator button.arithmetic:focus,
.calculator button.arithmetic:hover {
    background-color: #899;
}
.calculator button.dice {
    vertical-align: top;
    min-height: 60px;
    min-width: 60px;
    padding: 0;
}
.calculator button.dice:hover svg {
    color: #000;
}
.calculator button.compare {
    background-color: #9A9;
}
.calculator button.compare:active,
.calculator button.compare:focus,
.calculator button.compare:hover {
    background-color: #797;
}
.calculator button.macro {
    background-color: #FFE5B4;
}
.calculator button.macro:active,
.calculator button.macro:focus,
.calculator button.macro:hover {
    background-color: #EFD5A4;
}
.calculator button.expansion {
    background-color: #EDA;
}
.calculator button.expansion:active,
.calculator button.expansion:focus,
.calculator button.expansion:hover {
    background-color: #DC9;
}
.calculator button.macro span.macro-info {
    color: #888;
    font-size: smaller;
}
.calculator button.new-macro {
    background-color: #CB8;
    width: 46%;
}
.calculator button.new-macro:active,
.calculator button.new-macro:focus,
.calculator button.new-macro:hover {
    background-color: #DC9;
}
.calculator button.func {
    height: 5rem;
    width: 100%;
    background-color: #CDC;
}
.calculator button.func:active,
.calculator button.func:focus,
.calculator button.func:hover {
    background-color: #9A9;
}
.calculator .buttons button.roll {
    background-color: #e22;
}
.calculator .buttons button.roll:active,
.calculator .buttons button.roll:focus,
.calculator .buttons button.roll:hover {
    background-color: #b22;
}

