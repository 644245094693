svg.blue {
    color: #007bff;
}
svg.indigo {
    color: #6610f2;
}
svg.purple {
    color: #6f42c1;
}
svg.pink {
    color: #e83e8c;
}
svg.red {
    color: #dc3545;
}
svg.orange {
    color: #fd7e14;
}
svg.yellow {
    color: #ffc107;
}
svg.green {
    color: #28a745;
}
svg.teal {
    color: #20c997;
}
svg.cyan {
    color: #17a2b8;
}
svg.white {
    color: #fff;
}
svg.gray {
    color: #868e96;
}
svg.gray-dark {
    color: #333;
}
svg.primary {
    color: #333;
}
svg.secondary {
    color: #555;
}
svg.success {
    color: #28a745;
}
svg.info {
    color: #17a2b8;
}
svg.warning {
    color: #ffc107;
}
svg.danger {
    color: #dc3545;
}
svg.light {
    color: #fff;
}
svg.dark {
    color: #555;
}
svg {
    fill: currentColor;
}
